@import "../../../../variabels.scss";

.Home {
  background: url("/assets/images/home-bg.png") no-repeat center;
  background-size: cover;
  height: 80vh;

  .home-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .logo {
      padding-top: 7vh;
      img {
        height: 130px;
      }

      @include phone {
        padding-left: 20px;
        img {
          height: 90px;
        }
      }
    }

    .text {
      color: #fff;
      font-size: 7vmin;
      line-height: 8vmin;
      padding-bottom: 7vh;
      p {
        margin: 0;
      }
      .l1 {
        font-family: GothamLight;
      }
      .l2 {
        font-family: GothamMedium;
      }
    }
  }
}
