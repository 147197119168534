.ServiceItem {
  padding-top: 20px;
  padding-bottom: 60px;

  .item-row {
    display: flex;
  }
  .icon-container {
    img {
      height: 80px;
    }
  }
  .text-area {
    padding-left: 30px;
    .title {
      margin-top: -4px;
      font-family: GothamMedium;
      padding-bottom: 6px;
      font-size: 18px;
    }
    .body {
      font-size: 16px;
    }
  }
}
