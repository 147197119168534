.Service {
  padding: 15vh 0;

  .page-heading {
    font-size: 45px;
    line-height: 50px;
    padding-bottom: 10vh;
    p {
      margin-bottom: 0;
    }
  }

  .service-list-area {
    .sub-heading {
      font-size: 18px;
      font-family: GothamMedium;
      padding-bottom: 60px;
      text-align: justify;
      line-height: 30px;
    }
    .service-items {
      .ServiceItem {
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}
