@import "../../../../variabels.scss";

.Contact {
  color: #ffffff;
  min-height: 350px;
  color: #eee;
  background: #000000;

  .SectionHeader {
    color: #aaaaaa;
    .bar {
      background: #aaaaaa;
    }
  }

  .body {
    .title {
      font-family: "GothamMedium";
      font-size: 16px;
      line-height: 24px;
      margin-top: 10px;
      margin-bottom: 15px;
      color: #fff;
    }
    .address {
      font-family: "GothamLight";
      font-weight: bold;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      a {
        color: #eee;
        text-decoration: none;
      }
    }

    .bottom-link {
      margin-top: 40px;
      font-family: "GothamLight";
      font-weight: bold;
      font-style: normal;
      font-size: 13px;
      line-height: 24px;
      color: #aaa;

      > span {
        flex: 1;
        min-width: 150px;
        a {
          color: #aaa;
          text-decoration: none;
        }
      }
    }
  }
}
