.PrivacyPolicy {
  color: #222;
  font-family: Gotham;
  padding: 20px 0;
  font-size: 16px;
  h1,
  h2 {
    font-size: 16px;
    font-family: GothamMedium;
  }
}
