.About {
  padding: 15vh 0;

  .page-heading {
    font-size: 45px;
    line-height: 50px;
    padding-bottom: 10vh;
    p {
      margin-bottom: 0;
    }
  }

  .text {
    text-align: justify;
    font-size: 18px;
    line-height: 30px;
  }
}
