@import "./variabels.scss";

@font-face {
  font-family: "Gotham";
  src: url("//db.onlinewebfonts.com/t/0ff8990774ec4865c7f45befbafd4910.eot");
  src: url("//db.onlinewebfonts.com/t/0ff8990774ec4865c7f45befbafd4910.eot?#iefix") format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/0ff8990774ec4865c7f45befbafd4910.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/0ff8990774ec4865c7f45befbafd4910.woff") format("woff"),
    url("//db.onlinewebfonts.com/t/0ff8990774ec4865c7f45befbafd4910.ttf") format("truetype"),
    url("//db.onlinewebfonts.com/t/0ff8990774ec4865c7f45befbafd4910.svg#Gotham") format("svg");
}

@font-face {
  font-family: "GothamMedium";
  src: url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.eot");
  src: url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.eot?#iefix") format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.woff") format("woff"),
    url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.ttf") format("truetype"),
    url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.svg#Gotham") format("svg");
}

@font-face {
  font-family: "GothamBold";
  src: url("//db.onlinewebfonts.com/t/5d6f50ed2dde0e4ad455448f896c09f0.eot");
  src: url("//db.onlinewebfonts.com/t/5d6f50ed2dde0e4ad455448f896c09f0.eot?#iefix") format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/5d6f50ed2dde0e4ad455448f896c09f0.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/5d6f50ed2dde0e4ad455448f896c09f0.woff") format("woff"),
    url("//db.onlinewebfonts.com/t/5d6f50ed2dde0e4ad455448f896c09f0.ttf") format("truetype"),
    url("//db.onlinewebfonts.com/t/5d6f50ed2dde0e4ad455448f896c09f0.svg#Gotham") format("svg");
}

@font-face {
  font-family: "GothamLight";
  src: url("//db.onlinewebfonts.com/t/fc74760c72164d5321ac886797ce62eb.eot");
  src: url("//db.onlinewebfonts.com/t/fc74760c72164d5321ac886797ce62eb.eot?#iefix") format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/fc74760c72164d5321ac886797ce62eb.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/fc74760c72164d5321ac886797ce62eb.woff") format("woff"),
    url("//db.onlinewebfonts.com/t/fc74760c72164d5321ac886797ce62eb.ttf") format("truetype"),
    url("//db.onlinewebfonts.com/t/fc74760c72164d5321ac886797ce62eb.svg#Gotham") format("svg");
}

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "GothamLight", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.gotham {
  font-family: Gotham;
}
.gotham-bold {
  font-family: GothamBold;
}
.gotham-medium {
  font-family: GothamMedium;
}
.gotham-light {
  font-family: GothamLight;
  font-weight: bold;
}

.page-section {
  height: 100vh;
  padding: 0 20px;
}

.page-section-auto {
  height: auto;
}

.h-gap {
  height: 50px;
}
.h4-gap {
  height: 200px;
}

.italic {
  font-style: italic;
}

.blackish-background {
  background: linear-gradient(90deg, $blackishFrom, $blackishTo);
}
