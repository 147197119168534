@import "../../../../variabels.scss";

.Production {
  background: url("/assets/images/fp-trading-bg-2.png") no-repeat center;
  background-size: cover;
  height: 100vh;

  .production-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    .text {
      padding-bottom: 10vh;

      img {
        height: 30vmin;
      }
    }
  }
}
